<template>
	<div class="body">
		<Row :gutter="10">
			<Col>
				<Input placeholder="用户名称" v-model="SearchData.name" style="width: 200px;"></Input>
			</Col>
			<Col>
				<Input placeholder="AI套餐名称" v-model="SearchData.goods_name" style="width: 200px;"></Input>
			</Col>
			<Col>
				<Button type="primary" @click="init">搜索</Button>
			</Col>
		</Row>
		<Table :data="data" :columns="columns" style="margin-top: 20px;" :loading="loading">
			<template slot="payment" slot-scope="{row}">
				<span :class="{'weixin': row.payment == 2,'zhifubao': row.payment == 1}">{{ pay_type[row.payment] }}</span>
			</template>
		</Table>
		<Page style="text-align: right;margin-top: 10px;" @on-change="up_current_page" @on-page-size-change="up_page_size" :total="SearchData.total"
			:page-size="SearchData.page_size" :current="SearchData.current_page" :page-size-opts="[10,20,30]" :show-total="true" :show-sizer="true"></Page>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: [],
				pay_type: ['','支付宝','微信'],
				loading: false,
				columns: [{
					title: 'ID',
					key: 'id',
					align: 'center',
					minWidth: 50
				},{
					title: '购买商户',
					key: 'name',
					align: 'center',
					minWidth: 150
				},{
					title: 'AI套餐名称',
					key: 'goods_name',
					align: 'center',
					minWidth: 150
				},{
					title: '支付金额',
					key: 'money',
					align: 'center',
					minWidth: 100
				},{
					title: '购买数量（次）',
					key: 'qty',
					align: 'center',
					minWidth: 50
				},{
					title: '支付方式',
					slot: 'payment',
					align: 'center',
					minWidth: 100
				},{
					title: '支付时间',
					key: 'pay_time',
					align: 'center',
					minWidth: 150
				}],
				SearchData: {
					name: null,
					goods_name: null,
					total: 0,
					current_page: 1,
					page_size: 10,
				}
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				let _this = this
				this.loading = true
				this.requestApi('/adm/get_ai_record',{SearchData: this.SearchData}).then(
					(res) => {
						_this.SearchData.current_page = res.data.current_page
						_this.SearchData.page_size = res.data.per_page
						_this.SearchData.total = res.data.total
						_this.data = res.data.data
						_this.loading = false
					}
				)
			},
			up_current_page(e) {
				this.SearchData.current_page = e
				this.init()
			},
			up_page_size(e) {
				this.SearchData.page_size = e
				this.init()
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		padding: 10px;
		background-color: #fff;
	}
	.weixin {
		color: #fff;
		padding: 5px;
		background-color: #0CB500;
	}
	.zhifubao {
		color: #fff;
		padding: 5px;
		background-color: #039FEB;
	}
</style>
